import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../../helpers/Colors";
import {BiSearch} from "react-icons/bi";
import {FaUserCircle} from "react-icons/fa";
import {usersDummy} from "../../../helpers/data";


const ChatItem = ({user, index, activeIndex, setActiveIndex, setShoChat}) => {

    const useStyles = makeStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            padding: '15px',
            background: activeIndex === index ? Colors.primary1 : Colors.primary2,
            // borderBottom: `1px solid ${Colors.light2}`,
            '& .icon': {
                fontSize: '46px',
                color: Colors.light2,
                background: 'transparent'
            },
            '& div': {
                marginLeft: '12px',
                '& .name': {
                    color: Colors.light2,
                    fontSize: '13px',
                    fontWeight: 'bold',
                    margin: '0 0 2px',

                },
                '& .time': {
                    color: Colors.light2,
                    fontSize: '12px',
                    margin: '0',
                }
            }
        }
    })

    const classes = useStyles()

    const onChatClick = () => {
        setActiveIndex(index)
        setShoChat(false)
    }

    return (
        <div className={classes.container}  onClick={onChatClick}>
            <FaUserCircle className={'icon'}/>
            <div>
                <h6 className={'name'}>{user.name}</h6>
                <h6 className={'time'}>{user.time}</h6>
            </div>
        </div>
    )
}

const ChatList = ({setShoChat}) => {

    const useStyles = makeStyles({
        container: {
            height: '100%',
            overflow: 'auto',
            background: Colors.primary2,
            cursor: 'pointer',
            '&::-webkit-scrollbar': {
                display: 'block !important',
                width: '5px',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.light2,
                borderRadius: '10px',
                height: '20%'
            },
            '& .search-bar': {
                display: 'flex',
                alignItems: 'center',
                padding: '15px',
                // borderBottom: `1px solid ${Colors.light2}`,
                '& .icon': {
                    fontSize: '22px',
                    color: Colors.light3,
                },
                '& input': {
                    border: 0,
                    width: '80%',
                    background: Colors.primary2,
                    marginLeft: '8px',
                    color: Colors.light3,
                    '&:focus': {
                        outline: 0
                    },
                    '&::placeholder': {
                        color: Colors.light3
                    }
                }
            }
        },
    })

    const classes = useStyles()

    const [activeIndex, setActiveIndex] = useState(0);

    const [filteredData, setFilteredData] = useState([]);

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
       if (searchValue !== ''){
           const filteredData = usersDummy.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
           if (filteredData.length === 0){
               setFilteredData([]);
           }else {
               setFilteredData(filteredData);
           }
       } else {
           setFilteredData([]);
       }
    }, [searchValue]);

    const data = filteredData.length !== 0 ? filteredData : usersDummy

    return (
        <div className={classes.container}>
            <div className={'search-bar'}>
                <BiSearch className={'icon'}/>
                <input type="text" placeholder={'Search Here...'}
                       value={searchValue} onChange={e => setSearchValue(e.target.value)}/>
            </div>
            {
                data.map((user, i) => (
                    <ChatItem setShoChat={setShoChat} key={i} user={user} index={i}
                              activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
                ))
            }
        </div>
    );
};

export default ChatList;
