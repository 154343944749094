import React from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../helpers/Colors";
import RequestProductTable from "../requestProduct/section/RequestProductTable";
import UserTable from "./section/UserTable";
import {useMediaQuery} from "@mui/material";

const UserTrackingPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            minHeight: '100vh',
            width: is1025pxBelow ? '90%' : '70%',
            padding: is430pxBelow ? '25px 0' : '35px 0',
            margin: '0 auto',
        },
        parentContainer: {
            height: '100%',
            background: Colors.primaryLight
        }
    })

    const classes = useStyles()

    return (
        <div className={classes.parentContainer}>
            <div className={classes.container}>
                <UserTable />
            </div>
        </div>
    );
};

export default UserTrackingPage;
