import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../helpers/Colors";
import BannerTable from "./section/BannerTable";
import {useMediaQuery} from "@mui/material";
import axios from "axios";
import FullScreenProgress from "../../helpers/FullScreenProgress";

const BannerPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            // height: '100vh',
            width: is1025pxBelow ? '90%' : '70%',
            padding: is430pxBelow ? '25px 0' : '35px 0',
            margin: '0 auto',
        },
        parentContainer: {
            height: '100%',
            background: Colors.primaryLight
        }
    })

    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const [lifecarebanners , setLifeCareBanners] = useState([]);
    const [reload1 , setReload1] = useState(false);
    const [reload2 , setReload2] = useState(false);

    useEffect(() => {
        axios.get('/get/banners').then(res => {
            setBanners(res.data)
            setLoading(false)
        });
        
    }, [reload1]);

    useEffect(()=>{
        axios.get('/get/lifecarebanners').then(res => {
            setLifeCareBanners(res.data)
            setLoading(false)
        });
    },[reload2])


    const classes = useStyles()

    return (
        <div className={classes.parentContainer}>
            <div className={classes.container}>
                <BannerTable banners={banners} setBanners={setBanners} title={"WebOrder Banner Setting"} setReload={setReload1} reload={reload1} slug={"add-banner"} editSlug={'edit-banner'} deleteSlug={"banner"} />
            </div>
            <div className={classes.container}>
                <BannerTable banners={lifecarebanners} setBanners={setLifeCareBanners} title={"Lifecare Banner Setting"} setReload={setReload2} reload={reload2} slug={"add-lifecare-banner"} deleteSlug={"lifecarebanner"}  editSlug={'edit-lifecare-banner'} />
            </div>
            <FullScreenProgress open={loading} setOpen={setLoading}/>
        </div>
    );
};

export default BannerPage;
