import React, {useEffect, useState} from 'react';
import {useMediaQuery} from "@mui/material";
import {makeStyles} from "@mui/styles";
import authBg from "../../assets/authBg.jpg";
import {Colors} from "../../helpers/Colors";
import {useDispatch} from "react-redux";
import logo from "../../assets/logoWhite.png";
import CustomToast from "../../helpers/Toast";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

const ForgotPasswordPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const defaultData = {
        password: '',
        retypePassword: ''
    }

    const [data, setData] = useState(defaultData);

    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(true);
    const [errorType, setErrorType] = useState('error');
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const useStyles = makeStyles({
        container: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: `url(${authBg})`,
            '& .logo': {
                width: is430pxBelow ? '150px' : '180px',
            },
            '& .heading': {
                fontSize: is430pxBelow ? '20px' : '24px',
                color: Colors.light,
                letterSpacing: '1px',
                margin: is430pxBelow ? '20px 0' : '30px 0',
                '& span': {
                    fontWeight: 'bold',
                }
            },
            '& .card-container': {
                width: is1025pxBelow ? (is770pxBelow ? (is430pxBelow ? '90%' : '50%') : '38%') : '28%',
                background: Colors.primary + '33',
                borderRadius: '5px',
                padding: is430pxBelow ? '20px' : '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                '& .text-con': {
                    width: '100%',
                    '& h6': {
                        color: Colors.light2,
                        letterSpacing: '1px',
                        fontSize: is430pxBelow ? '14px' : '16px',
                        marginBottom: '10px',
                    },
                    '& input': {
                        width: '100%',
                        border: `2px solid ${Colors.light2}`,
                        borderRadius: '5px',
                        padding: is430pxBelow ? '8px' : '10px',
                        letterSpacing: '1px',
                        background: 'transparent',
                        color: Colors.light2,
                        marginBottom: '20px',
                        '&::placeholder': {
                            letterSpacing: '1px',
                            color: Colors.light2,
                        },
                        '&:focus': {
                            outline: 0,
                        }
                    }
                },
                '& .forgot-password': {
                    marginLeft: 'auto',
                    letterSpacing: '1px',
                    color: Colors.light2,
                    cursor: 'pointer',
                    fontSize: is430pxBelow ? '14px' : '16px',
                },
                '& button': {
                    width: '100%',
                    marginTop: '20px',
                    fontFamily: 'inherit',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    fontSize: is430pxBelow ? '16px' : '18px',
                    cursor: 'pointer',
                    padding: is430pxBelow ? '4px' : '6px',
                    background: Colors.secondary,
                    color: Colors.light,
                    borderRadius: '5px',
                    border: 0,
                    '&:focus': {
                        outline: 0,
                    }
                }
            }
        }
    })

    const classes = useStyles();

    const navigate = useNavigate();

    const {id} = useParams();

    useEffect(() => {
        console.log(id)
        axios.get(`/get/adminByForgotPasswordId?forgotPasswordId=${id}`).then(res => {
            if (res.data === null) {
                navigate(-1);
            } else {
                setEmail(res.data.email)
            }
            setLoading(false)
        })
    }, [id, navigate]);


    const onChangePasswordClick = () => {
        const {password, retypePassword} = data
        if (password === retypePassword){
            setLoading(true)
            axios.post('/post/resetPasswordSuperAdmin', {data: {email, password}}).then(res => {
                if (res.data.key === 'success') {
                    setErrorMessage('Password is changed')
                    setErrorType('success')
                    setOpenError(true)
                    navigate('/')
                } else {
                    setErrorMessage('Something went wrong')
                    setErrorType('error')
                    setOpenError(true)
                }
                setLoading(false)
            })
        }
    }

    const onDataChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    return (
        <div className={classes.container}>
            <img className={'logo'} src={logo} alt="Logo"/>
            <h5 className={'heading'}>Welcome to <span>Lifecare Admin</span></h5>
            <div className={'card-container'}>
                <div className={'text-con'}>
                    <h6>New Password</h6>
                    <input name={'password'}
                           value={data.password} onChange={onDataChange}
                           type="password" placeholder={'Enter Your New Password'}/>
                </div>
                <div className={'text-con'}>
                    <h6>Password</h6>
                    <input name={'retypePassword'}
                           value={data.retypePassword} onChange={onDataChange}
                           type="password" placeholder={'Confirm Your New Password'}/>
                </div>
                <button onClick={onChangePasswordClick}>Change Password</button>
            </div>
            <CustomToast type={errorType} openToast={openError} setOpenToast={setOpenError} message={errorMessage}/>
            <FullScreenProgress open={loading} setOpen={setLoading}/>
        </div>
    );
};

export default ForgotPasswordPage;
