import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import logo from '../assets/logo.png'
import profile from '../assets/navbar/profile.png'
import {MdOutlineDashboard, MdOutlineNotificationsActive, MdOutlineTextsms} from "react-icons/md";
import {Colors} from "../helpers/Colors";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from "@mui/material";
import {BiMenu} from "react-icons/bi";
import SideDrawer from "./SideDrawer";
import {CgTrack} from "react-icons/cg";
import {IoMdImages} from "react-icons/io";
import ProfileOnHover from "./ProfileOnHover";

const NavBar = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const [drawerOpen, setDrawerOpen] = useState(false);

    const navbarHeight = is380pxBelow ? '65px' : '75px';

    const useStyles = makeStyles({
        container: {
            display: 'flex',
            padding: is770pxBelow ? (is430pxBelow ? '0 10px' : '0 25px') : '0 35px',
            background: Colors.light,
            height: navbarHeight,
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            zIndex: 10,
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .logo-con': {
                width: is770pxBelow ? (is430pxBelow ? (is380pxBelow ? '50%' : '40%') : '28%') : '12%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& .icon': {
                    fontSize: '26px',
                    color: Colors.primary
                },
                '& .logo': {
                    width: is770pxBelow ? '80%' : '100%',
                    maxHeight: is770pxBelow ? '80%' : '100%',
                    maxWidth: is770pxBelow ? '80%' : '100%',
                },
            },
            '& .nav-list': {
                width: '67%',
                display: is770pxBelow ? 'none' : 'flex',
                height: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& div': {
                    width: '20%',
                    display: 'flex',
                    height: '100%',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& h6': {
                        fontSize: is1025pxBelow ? '14px' : '16px',
                        color: Colors.dark3,
                        margin: 0
                    },
                    '& .icon': {
                        display: is1025pxBelow ? 'none' : 'auto',
                        fontSize: '24px',
                        color: Colors.dark3,
                        marginRight: '6px'
                    },
                },
                '& .active': {
                    background: Colors.light1,
                    '& h6': {
                        color: Colors.primary,
                    },
                    '& .icon': {
                        color: Colors.primary,
                    },
                }
            },
            '& .profile-con': {
                width: is770pxBelow ? (is430pxBelow ? (is380pxBelow ? '26%' : '32%') : '20%')  : '13%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .notification': {
                    fontSize: is1025pxBelow ? '24px' : '28px',
                    color: Colors.primary,
                    marginRight: '6px'
                },
                '& div': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& h5': {
                        fontSize: is430pxBelow ? '16px' : '18px',
                        display: is380pxBelow ? 'none' : 'auto',
                        color: Colors.primary,
                        margin: 0
                    },
                    '& img': {
                        width: is1025pxBelow ? '35px' : '50px',
                        margin: is1025pxBelow ? '0 6px 0 0' : '0 10px 0 0'
                    }
                }
            }
        }
    })

    const classes = useStyles();

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <>
            <div className={classes.container}>
                <div className={'logo-con'}>
                    <BiMenu className={'icon'} onClick={() => setDrawerOpen(true)}/>
                    <img onClick={() => navigate('/')} src={logo} className={'logo'} alt="Logo"/>
                </div>
                <div className={'nav-list'}>
                    <div onClick={() => navigate('/')} className={location.pathname === '/' && 'active'}>
                        <MdOutlineDashboard className={'icon'}/>
                        <h6>Dashboard</h6>
                    </div>
                    <div onClick={() => navigate('/request-product')}
                         className={location.pathname === '/request-product' && 'active'}>
                        <AiOutlineQuestionCircle className={'icon'}/>
                        <h6>Request Product</h6>
                    </div>
                    <div onClick={() => navigate('/push-notification')}
                         className={location.pathname === '/push-notification' && 'active'}>
                        <MdOutlineTextsms className={'icon'}/>
                        <h6>Push Notification</h6>
                    </div>
                    <div onClick={() => navigate('/banners')} className={location.pathname === '/banners' && 'active'}>
                        <IoMdImages className={'icon'}/>
                        <h6>Banner</h6>
                    </div>
                    <div onClick={() => navigate('/user-tracking')}
                         className={location.pathname === '/user-tracking' && 'active'}>
                        <CgTrack className={'icon'}/>
                        <h6>User Tracking</h6>
                    </div>
                </div>
                <div className={'profile-con'}>
                    <MdOutlineNotificationsActive onClick={() => navigate('/chat')} className={'notification'}/>
                    <ProfileOnHover />
                </div>
            </div>
            <SideDrawer visible={drawerOpen} setVisible={setDrawerOpen}/>
            <div style={{height: navbarHeight}}/>
        </>
    );
};

export default NavBar;
