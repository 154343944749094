import React, {useState} from 'react';
import {
    Paper,
    Table, TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    useMediaQuery
} from "@mui/material";
import {makeStyles, styled} from "@mui/styles";
import {Colors} from "../../../helpers/Colors";
import {data} from "../../../helpers/data";
import {AiOutlineEye} from "react-icons/ai";
import UserInfoDialog from "../../../shared/userInfoDialog";

const UserTable = () => {

    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const columns = [
        {
            name: 'Full Name',
            align: 'left',
        },
        {
            name: 'Email',
            align: 'center',
        },
        {
            name: 'Join Date',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [open, setOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        title: {
            fontWeight: 'bold',
            color: Colors.dark3,
            margin: is430pxBelow ? '0 0 15px' : '0 0 20px',
            fontSize: is430pxBelow ? '16px' : '20px'
        },
        container: {
            maxHeight: 650,
            borderRadius: '5px',
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            margin: 0,
            fontSize: is430pxBelow ? '11px' : '16px'
        },
        tableRow:{
            '&:hover': {
                backgroundColor: Colors.primaryLight+' !important',
            },
        },
        rowTextStyle: {
            color: Colors.dark3,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px',
            margin: 0,
            '& .checked':{
                fontSize: '22px',
                color: Colors.success,
            },
            '& .close':{
                fontSize: '20px',
                color: Colors.error,
            },
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            justifyContent: 'center',
            background: Colors.secondary,
            padding: '5px 10px',
            border: `1px solid ${Colors.secondary}`,
            width: is770pxBelow ? (is430pxBelow ? '100%' : '80%') : '40%',
            borderRadius: '5px',
            transition: 'all .4s',
            cursor: 'pointer',
            '&:hover': {
                transition: 'all .4s',
                border: `1px solid ${Colors.primary}`,
                background: Colors.primary,
                '& .icon': {
                    color: Colors.secondary,
                },
                '& h6': {
                    color: Colors.secondary,
                }
            },
            '& .icon': {
                color: Colors.primary,
                fontSize: is430pxBelow ? '11px' : '20px',
                margin: '0 4px 0 0'
            },
            '& h6': {
                color: Colors.primary,
                fontSize: is430pxBelow ? '11px' : '14px',
                margin: 0
            }
        }
    });

    const classes = useStyles();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: Colors.primary,
            color: Colors.light,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    return (
        <>
            <h6 className={classes.title}>New User History</h6>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map(col => (
                                        <StyledTableCell align={col.align}>
                                            <h6 className={classes.colTextStyle}>{col.name}</h6>
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row}>
                                        <TableCell align={'left'}>
                                            <h6 className={classes.rowTextStyle}>Furiq Technologies</h6>
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            <h6 className={classes.rowTextStyle} style={{margin: '0 auto'}}>mail2furiq@gmail.com</h6>
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            <h6 className={classes.rowTextStyle} style={{margin: '0 auto'}}>{new Date().toLocaleDateString()}</h6>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <div className={classes.actionContainer} onClick={() => setOpen(true)}>
                                                {
                                                    !is430pxBelow &&
                                                    <AiOutlineEye className={'icon'}/>
                                                }
                                                <h6>View</h6>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    data.length === 0 ? <h6 style={{textAlign: 'center', marginTop: '25px', color: Colors.dark3}}>No Data Found</h6> : <></>
                }
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <UserInfoDialog open={open} setOpen={setOpen}/>
            </Paper>
        </>
    );
};

export default UserTable;
