import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../../helpers/Colors";
import {FaPause, FaPlay} from "react-icons/fa";
import {useMediaQuery} from "@mui/material";
import {AiOutlineArrowLeft} from "react-icons/ai";
import axios from "axios";
import {IoIosArrowDown} from "react-icons/io";
import audioBg from '../../../assets/audioBg.jpg'
import {BiUser} from "react-icons/bi";

const SingleAudio = ({audio, setAudios}) => {

    const [duration, setDuration] = useState();
    const [curTime, setCurTime] = useState();
    const [playing, setPlaying] = useState(false);
    const [clickedTime, setClickedTime] = useState();

    const [loading, setLoading] = useState(false);

    const audioRef = useRef(null)

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    useEffect(() => {
        // state setters wrappers

        const audio = audioRef.current;

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurTime(audio.currentTime);
        }

        const setAudioTime = () => {
            setCurTime(audio.currentTime)
        };
        const endAudio = () => setPlaying(false);


        // DOM listeners: update React state on DOM events
        audio.addEventListener("loadeddata", setAudioData);
        audio.addEventListener("timeupdate", setAudioTime);
        audio.addEventListener("ended", endAudio);

        // React state listeners: update DOM on React state changes
        playing ? audio.play() : audio.pause();

        if (clickedTime && clickedTime !== curTime) {
            audio.currentTime = clickedTime;
            setClickedTime(null);
        }

        return () => {
            audio.removeEventListener("loadeddata", setAudioData);
            audio.removeEventListener("timeupdate", setAudioTime);
            audio.removeEventListener("ended", endAudio);
        }
    }, [clickedTime, curTime, playing]);

    const playPercentage = parseInt(`${(curTime / duration) * 100}`)

    const useStyles = makeStyles({
        container: {
            backgroundColor: Colors.primary2,
            marginBottom: '15px',
            borderRadius: '5px',
            padding: '8px 0',
            position: 'relative',
            width: is1025pxBelow ? (is770pxBelow ? (is430pxBelow ? '65%' : '50%') : '40%') : '30%',
            display: 'flex',
            paddingRight: '15px',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon-player': {
                color: Colors.light,
                fontSize: '42px',
                background: Colors.secondary,
                borderRadius: '20px',
                margin: '0 6px',
                padding: '10px',
            },
            '& .details': {
                width: '85%',
                '& .line-con': {
                    width: '100%',
                    height: '4px',
                    background: Colors.light,
                    '& div':{
                        background: Colors.secondary,
                        width: `${playPercentage}%`,
                        transition: 'all .1s',
                        height: '100%',
                    }
                }
            },
            '& .time': {
                position: 'absolute',
                bottom: '8px',
                right: '15px',
                '& h6': {
                    color: Colors.dark1,
                    fontSize: '11px',
                    margin: 0
                }
            },
            '& .arrow': {
                position: 'absolute',
                top: '8px',
                right: '15px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                '& .icon': {
                    color: Colors.light4,
                    fontSize: '18px',
                    margin: 0
                },
                '& button': {
                    background: Colors.error,
                    border: 0,
                    padding: '2px 10px',
                    borderRadius: '5px',
                    transition: 'all .1s',
                    height: '0px',
                    color: Colors.light,
                    cursor: 'pointer',
                    overflow: 'hidden',
                    visibility: 'hidden',
                    '&:focus': {
                        outline: 0
                    }
                },
                '&:hover': {
                    '& button': {
                        transition: 'all .1s',
                        padding: '0px 10px',
                        // border: `1px solid ${Colors.primary}`,
                        height: '30px',
                        visibility: 'visible',
                    },
                }
            },
        },
    })

    const classes = useStyles()

    const onDeleteClick = () => {
        if (!loading){
            setLoading(true);
            axios
                .delete('/delete/audioOrder', {data: {data: [audio]}})
                .then(() => {
                    axios.get('/get/audioOrders').then(dbRes => {
                        setAudios(dbRes.data);
                        setLoading(false);
                    });
                });
        }
    };

    function formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
    }

    return (
        <div>
            <audio ref={audioRef}>
                <source src={audio.audioUrl} type="audio/ogg"/>
            </audio>
            <div className={classes.container}>
                {
                    !playing ?
                        <FaPlay className={'icon-player'} onClick={() => setPlaying(true)}/> :
                        <FaPause className={'icon-player'} onClick={() => setPlaying(false)}/>
                }
                <div className={'details'}>
                    <div className={'line-con'}>
                        <div />
                    </div>
                </div>
                <div className={'time'}>
                    <h6>{formatAMPM(new Date(audio.createdAt))}</h6>
                </div>
                <div className={'arrow'}>
                    <IoIosArrowDown className={'icon'}/>
                    <button onClick={onDeleteClick} >Delete</button>
                </div>
            </div>
        </div>
    )
}

const ChatConversations = ({setShoChat}) => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            height: '100%',
            overflow: 'auto',
            padding: is430pxBelow ? '0 15px 15px' : '15px',
            background: `url(${audioBg})`,
            '&::-webkit-scrollbar': {
                display: 'block !important',
                width: '3px',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.light2,
                borderRadius: '10px',
                height: '20%'
            },
            '& .app-bar': {
                padding: '10px 15px 15px',
                position: 'sticky',
                top: 0,
                background: 'transparent',
                display: 'flex',
                marginBottom: '25px',
                alignItems: 'center',
                borderBottom: `1px solid ${Colors.light4}`,
                zIndex: 5,
                '& .icon': {
                    color: Colors.light2,
                    fontSize: '22px',
                    marginRight: '10px'
                },
                '& .user-icon': {
                    color: Colors.secondary,
                    borderRadius: '50px',
                    padding: '5px',
                    border: `2px solid ${Colors.secondary}`,
                    fontSize: '42px',
                },
                '& h6': {
                    color: Colors.light2,
                    fontSize: '16px',
                    margin: '0 0 0 10px'
                }
            }
        },
    })

    const [audios, setAudios] = useState([]);

    const classes = useStyles()

    useEffect(() => {
        axios.get('/get/audioOrders').then(res => {
            setAudios(res.data)
        })
    }, [])

    return (
        <div className={classes.container}>
            {/*{*/}
            {/*    is430pxBelow &&*/}
            {/*    <div className={'app-bar'}>*/}
            {/*        <AiOutlineArrowLeft onClick={() => setShoChat(true)} className={'icon'}/>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className={'app-bar'}>
                {
                    is430pxBelow && (
                        <AiOutlineArrowLeft onClick={() => setShoChat(true)} className={'icon'}/>
                    )
                }
                <BiUser className={'user-icon'}/>
                <h6>Wakir Younus</h6>
            </div>
            {
                audios.map((audio, index) => (
                    <SingleAudio key={index} audio={audio} setAudios={setAudios}/>
                ))
            }
        </div>
    );
};

export default ChatConversations;
