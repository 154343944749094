import React from 'react';
import profile from "../assets/navbar/profile.png";
import {makeStyles} from "@mui/styles";
import {useMediaQuery} from "@mui/material";
import {Colors} from "../helpers/Colors";
import {setUser} from "../redux/slices/UserSlice";
import {useDispatch} from "react-redux";
import axios from "axios";

const ProfileOnHover = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            position: 'relative',
            '& .profile-con': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                '& h5': {
                    fontSize: is430pxBelow ? '16px' : '18px',
                    display: is380pxBelow ? 'none' : 'auto',
                    color: Colors.primary,
                    margin: 0
                },
                '& img': {
                    width: is1025pxBelow ? '35px' : '50px',
                    margin: is1025pxBelow ? '0 6px 0 0' : '0 10px 0 0'
                }
            },
            '&:hover': {
                '& .profile-list-hover': {
                    right: '0',
                }
            },
            '& .profile-list-hover': {
                position: 'absolute',
                top: '55px',
                display: 'flex',
                flexDirection: 'column',
                right: '-200px',
                width: '100px',
                transition: 'all .5s',
                paddingBottom: '10px',
                background: Colors.primary,
                '& .box': {
                    width: '15px',
                    height: '15px',
                    background: Colors.primary,
                    margin: '-5px auto 0',
                    transform: 'rotate(45deg)'
                },
                '& .list-item': {
                    padding: '5px',
                    cursor: 'pointer',
                    '& h6': {
                        color: Colors.light,
                        fontSize: '14px',
                        margin: 0
                    }
                }
            }
        }
    })

    const classes = useStyles();

    const dispatch = useDispatch();

    const onLogoutClick = () => {
        axios('/post/logoutSuperAdmin', {method: 'post', withCredentials: true}).then(res => {
            dispatch(setUser(null))
        })
    }

    return (
        <div className={classes.container}>
            <div className={'profile-con'}>
                <img src={profile} alt="Profile"/>
                <h5>Mercy</h5>
            </div>
            <div className={'profile-list-hover'}>
                <div className={'box'}/>
               <div className={'list-item'}>
                   <h6>Profile</h6>
               </div>
                <div onClick={onLogoutClick} className={'list-item'}>
                    <h6>Logout</h6>
                </div>
            </div>
        </div>
    );
};

export default ProfileOnHover;
