import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../helpers/Colors";
import RequestProductTable from "./section/RequestProductTable";
import {useMediaQuery} from "@mui/material";
import axios from "axios";

const RequestProductPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            height: '100vh',
            width: is1025pxBelow ? '90%' : '70%',
            padding: is430pxBelow ? '25px 0' : '35px 0',
            margin: '0 auto',
        },
        parentContainer: {
            height: '100%',
            background: Colors.primaryLight
        }
    })

    const classes = useStyles()

    const [productRequests, setProductRequests] = useState([]);

    useEffect(() => {
        axios.get('/get/productRequests').then(res => {
            setProductRequests(res.data);
        })
    }, []);


    return (
        <div className={classes.parentContainer}>
            <div className={classes.container}>
                <RequestProductTable productRequests={productRequests} setProductRequests={setProductRequests}/>
            </div>
        </div>
    );
};

export default RequestProductPage;
