import React from 'react';
import {makeStyles} from "@mui/styles";
import one from '../../../assets/home/c1.png'
import two from '../../../assets/home/c2.png'
import three from '../../../assets/home/c3.png'
import four from '../../../assets/home/c4.png'
import {Colors} from "../../../helpers/Colors";
import {AiFillQuestionCircle} from "react-icons/ai";
import pushNotification from '../../../assets/navbar/push.png'
import banner from '../../../assets/navbar/banner white.png'
import userTracking from '../../../assets/navbar/user track.png'
import {useMediaQuery} from "@mui/material";

const HomeCards = () => {

    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            display: "grid",
            gridTemplateColumns: is430pxBelow ? '2fr 1fr' : '2fr 1fr 1fr 1fr',
            margin: '20px 0',
            gridGap: '10px',
            '& .card': {
                position: 'relative',
                '& img': {
                    width: '100%',
                    objectFit: 'cover',
                    height: is770pxBelow ?  (is430pxBelow ? '150px' : '170px') :  '200px',
                    borderRadius: '10px'
                },
                '& .d-sticky': {
                    top: is430pxBelow ? '15px' : '20px',
                    left: is430pxBelow ? '15px' : '20px',
                    right: is430pxBelow ? '15px' : '20px',
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& div': {
                        '& .title': {
                            fontSize: is770pxBelow ? (is380pxBelow ? '16px' : '18px') : '20px',
                            color: Colors.light,
                            margin: 0,
                        },
                        '& .sub-title': {
                            fontWeight: is770pxBelow ? 'auto' : '700',
                            margin: '5px 0 0 0',
                            width: is770pxBelow ? (is380pxBelow ? '90%' : '75%') : 'auto',
                            lineHeight: is770pxBelow ? '20px' : 'auto',
                            fontSize: is770pxBelow ? (is380pxBelow ? '14px' : '16px') : '20px',
                            color: Colors.light,
                        },
                        '& .value': {
                            fontWeight: 'bold',
                            margin: is770pxBelow ? (is430pxBelow ? '5px 0 0 0' : '10px 0 0 0') : '20px 0 0 0',
                            fontSize: is770pxBelow ? (is430pxBelow ? (is380pxBelow ? '18px' : '22px') : '32px') : '42px',
                            color: Colors.light,
                        },
                    },
                    '& .icon': {
                        fontSize: is770pxBelow ? (is380pxBelow ? '32px' : '40px') : '50px',
                        color: Colors.light,
                    }
                },
                '& .sticky': {
                    top: '0',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& div': {
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& img': {
                            maxWidth: is770pxBelow ? '70%' : '50%',
                            height: 'auto'
                        },
                        '& h6': {
                            textAlign: 'center',
                            fontWeight: is770pxBelow ? 'auto' : '700',
                            lineHeight: is770pxBelow ? '20px' : 'auto',
                            margin: '12px 0 0 0',
                            fontSize: is770pxBelow ? (is380pxBelow ? '14px' : '16px') : '18px',
                            color: Colors.light,
                        }
                    }
                }
            }
        }
    })

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={'card'}>
                <img src={one} alt="one"/>
                <div className={'d-sticky'}>
                    <div>
                        <h6 className={'title'}>Total No of</h6>
                        <h6 className={'sub-title'}>New Product Requests</h6>
                        <h6 className={'value'}>552</h6>
                    </div>
                    <AiFillQuestionCircle className={'icon'}/>
                </div>
            </div>
            <div className={'card'}>
                <img src={two} alt="one"/>
                <div className={'sticky'}>
                    <div>
                        <img src={pushNotification} alt="Push Notification"/>
                        <h6>Push Notification</h6>
                    </div>
                </div>

            </div>
            <div className={'card'}>
                <img src={three} alt="one"/>
                <div className={'sticky'}>
                    <div>
                        <img style={is430pxBelow ? {width: '35%'} : {}} src={banner} alt="Push Notification"/>
                        <h6>Banner Settings</h6>
                    </div>
                </div>
            </div>
            <div className={'card'}>
                <img src={four} alt="one"/>
                <div className={'sticky'}>
                    <div>
                        <img src={userTracking} alt="Push Notification"/>
                        <h6>User Tracking</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeCards;
