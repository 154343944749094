import React from 'react';
import {makeStyles} from "@mui/styles";
import {Drawer} from "antd";
import {MdOutlineDashboard, MdOutlineTextsms} from "react-icons/md";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import {IoMdImages} from "react-icons/io";
import {CgTrack} from "react-icons/cg";
import {useLocation, useNavigate} from "react-router-dom";
import {Colors} from "../helpers/Colors";
import {useMediaQuery} from "@mui/material";

const SideDrawer = ({visible, setVisible}) => {

    const onClose = () => {
        setVisible(false)
    }

    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            '& .nav-list': {
                // display: 'flex',
                // justifyContent: 'space-between',
                // alignItems: 'center',
                '& div': {
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    padding: '15px',
                    '& h6': {
                        fontSize: is430pxBelow ? '14px' : '16px',
                        color: Colors.dark3,
                        margin: 0
                    },
                    '& .icon': {
                        fontSize: is430pxBelow ? '20px' : '22px',
                        color: Colors.dark3,
                        marginRight: '6px'
                    },
                },
                '& .active': {
                    background: Colors.light1,
                    '& h6': {
                        color: Colors.primary,
                    },
                    '& .icon': {
                        color: Colors.primary,
                    },
                }
            },
        }
    })

    const classes = useStyles()

    const location = useLocation();
    const navigate = useNavigate();

    const onItemClick = (name) => {
        navigate(name)
        onClose()
    }

    return (
        <Drawer
            placement={'left'}
            closable={false}
            onClose={onClose}
            width={is430pxBelow ? '70%' : '35%'}
            bodyStyle={{padding: 0}}
            visible={visible}
            key={'left'}
        >
            <div className={classes.container}>
                <div className={'nav-list'}>
                    <div onClick={() => onItemClick('/')} className={location.pathname === '/' && 'active'}>
                        <MdOutlineDashboard className={'icon'}/>
                        <h6>Dashboard</h6>
                    </div>
                    <div onClick={() => onItemClick('/request-product')}
                         className={location.pathname === '/request-product' && 'active'}>
                        <AiOutlineQuestionCircle className={'icon'}/>
                        <h6>Request Product</h6>
                    </div>
                    <div onClick={() => onItemClick('/push-notification')}
                         className={location.pathname === '/push-notification' && 'active'}>
                        <MdOutlineTextsms className={'icon'}/>
                        <h6>Push Notification</h6>
                    </div>
                    <div onClick={() => onItemClick('/banners')} className={location.pathname === '/banners' && 'active'}>
                        <IoMdImages className={'icon'}/>
                        <h6>Banner</h6>
                    </div>
                    <div onClick={() => onItemClick('/user-tracking')}
                         className={location.pathname === '/user-tracking' && 'active'}>
                        <CgTrack className={'icon'}/>
                        <h6>User Tracking</h6>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default SideDrawer;
