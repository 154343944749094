export const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const usersDummy = [
    {
        name: 'Wakir Younus',
        time: '11:15 PM',
    },
    {
        name: 'Mohammed Asiq',
        time: '3:15 PM',
    },
    {
        name: 'Thanveer',
        time: '5:12 PM',
    },
    {
        name: 'Kadhar',
        time: '8:15 PM',
    },
    {
        name: 'Hamdan',
        time: '11:12 PM',
    },
    {
        name: 'Arif',
        time: '12:15 PM',
    },
    {
        name: 'Moiz',
        time: '6:15 PM',
    },
    {
        name: 'Harun',
        time: '11:12 PM',
    },
    {
        name: 'Akbar',
        time: '10:30 PM',
    }
]
