import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../helpers/Colors";
import authBg from '../../assets/authBg.jpg'
import logo from '../../assets/logoWhite.png'
import {useMediaQuery} from "@mui/material";
import {useDispatch} from "react-redux";
import {setUser} from "../../redux/slices/UserSlice";
import FullScreenProgress from "../../helpers/FullScreenProgress";
import axios from "axios";
import CustomToast from "../../helpers/Toast";

const AuthPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const defaultData = {
        email: '',
        password: '',
    }

    const [data, setData] = useState(defaultData);

    const [loading, setLoading] = useState(false);
    const [errorType, setErrorType] = useState('error');
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const useStyles = makeStyles({
        container: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: `url(${authBg})`,
            '& .logo': {
                width: is430pxBelow ? '150px' : '180px',
            },
            '& .heading': {
                fontSize: is430pxBelow ? '20px' : '24px',
                color: Colors.light,
                letterSpacing: '1px',
                margin: is430pxBelow ? '20px 0' : '30px 0',
                '& span': {
                    fontWeight: 'bold',
                }
            },
            '& .card-container': {
                width: is1025pxBelow ? (is770pxBelow ? (is430pxBelow ? '90%' : '50%') : '38%') : '28%',
                background: Colors.primary + '33',
                borderRadius: '5px',
                padding: is430pxBelow ? '20px' : '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                '& .text-con': {
                    width: '100%',
                    '& h6': {
                        color: Colors.light2,
                        letterSpacing: '1px',
                        fontSize: is430pxBelow ? '14px' : '16px',
                        marginBottom: '10px',
                    },
                    '& input': {
                        width: '100%',
                        border: `2px solid ${Colors.light2}`,
                        borderRadius: '5px',
                        padding: is430pxBelow ? '8px' : '10px',
                        letterSpacing: '1px',
                        background: 'transparent',
                        color: Colors.light2,
                        marginBottom: '20px',
                        '&::placeholder': {
                            letterSpacing: '1px',
                            color: Colors.light2,
                        },
                        '&:focus': {
                            outline: 0,
                        }
                    }
                },
                '& .forgot-password': {
                    marginLeft: 'auto',
                    letterSpacing: '1px',
                    color: Colors.light2,
                    cursor: 'pointer',
                    fontSize: is430pxBelow ? '14px' : '16px',
                },
                '& button': {
                    width: '100%',
                    marginTop: '20px',
                    fontFamily: 'inherit',
                    letterSpacing: '1px',
                    fontWeight: 'bold',
                    fontSize: is430pxBelow ? '16px' : '18px',
                    cursor: 'pointer',
                    padding: is430pxBelow ? '4px' : '6px',
                    background: Colors.secondary,
                    color: Colors.light,
                    borderRadius: '5px',
                    border: 0,
                    '&:focus': {
                        outline: 0,
                    }
                }
            }
        }
    })

    const classes = useStyles();

    const dispatch = useDispatch();

    const onLoginClick = () => {
        const {email, password} = data;
        if (email !== '' && password !== '') {
            setLoading(true);
            axios('/post/loginSuperAdmin', {
                method: "post",
                data: {data},
                withCredentials: true
            }).then(res => {
                if (res.data.key === 'success') {
                    dispatch(setUser(res.data.data))
                    setErrorMessage('Logged In')
                    setErrorType('success')
                    setOpenError(true)
                } else {
                    setErrorMessage(res.data.message)
                    setErrorType('error')
                    setOpenError(true)
                }
                setLoading(false);
            })
        } else {
            setErrorMessage('Fields Should not be empty')
            setErrorType('error')
            setOpenError(true)
        }
    }

    const onForgotPasswordClick = () => {
        const {email} = data;
        if (email !== ''){
            setLoading(true);
            axios.post('/post/forgotPasswordSuperAdmin', {email}).then(res => {
                if (res.data.key === 'success') {
                    setErrorMessage('Forgot Password Mail Send Successfully')
                    setErrorType('success')
                    setOpenError(true)
                } else {
                    setErrorMessage(res.data.message)
                    setErrorType('error')
                    setOpenError(true)
                }
                setLoading(false);
            })
        } else {
            setErrorMessage('Email Should not be empty')
            setErrorType('error')
            setOpenError(true)
        }
    }

    const onDataChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    return (
        <div className={classes.container}>
            <img className={'logo'} src={logo} alt="Logo"/>
            <h5 className={'heading'}>Welcome to <span>Lifecare Admin</span></h5>
            <div className={'card-container'}>
                <div className={'text-con'}>
                    <h6>Email ID</h6>
                    <input name={'email'}
                           value={data.email} onChange={onDataChange}
                           type="text" placeholder={'Enter Your Email Id'}/>
                </div>
                <div className={'text-con'}>
                    <h6>Password</h6>
                    <input name={'password'}
                           value={data.password} onChange={onDataChange}
                           type="password" placeholder={'Enter Your Password'}/>
                </div>
                <h6 className={'forgot-password'} onClick={onForgotPasswordClick}>Forgot Password?</h6>
                <button onClick={onLoginClick}>Login</button>
            </div>
            <CustomToast type={errorType} openToast={openError} setOpenToast={setOpenError} message={errorMessage}/>
            <FullScreenProgress open={loading} setOpen={setLoading}/>
        </div>
    );
};

export default AuthPage;
