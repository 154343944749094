import React from 'react';
import {makeStyles} from "@mui/styles";
import banner from '../../../assets/home/banner.jpg'
import {Colors} from "../../../helpers/Colors";
import {useMediaQuery} from "@mui/material";

const HomeBanner = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const useStyles = makeStyles({
        container: {
            position: 'relative',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            borderRadius: '10px',
            '& img': {
                width: '100%',
                height: is430pxBelow ? '130px' : 'auto',
                borderRadius: '10px',
            },
            '& div': {
                position: 'absolute',
                top: is770pxBelow ? 12 : 25,
                left: is770pxBelow ? 12 : 25,
                '& h6': {
                    fontWeight: is430pxBelow ? 'auto' : 'bold',
                    fontSize: '14px',
                    width: is430pxBelow ? '75%' : 'auto',
                    color: Colors.dark3,
                    margin: is1025pxBelow ? '5px 0 0 0' : '8px 0 0 0'
                },
                '& h5': {
                    // fontWeight: 'bold',
                    fontSize: is1025pxBelow ? (is430pxBelow ? '20px' : '24px') : '28px',
                    color: Colors.dark3,
                    margin: 0
                }
            }
        }
    })

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <img src={banner} alt={'Banner'}/>
            <div>
                <h5>Hello, Mercy</h5>
                <h6>Here is what’s happening with your admin dashboard</h6>
            </div>
        </div>
    );
};

export default HomeBanner;
