import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../../helpers/Colors";
import {useMediaQuery} from "@mui/material";
import CustomToast from "../../../helpers/Toast";
import FullScreenProgress from "../../../helpers/FullScreenProgress";
import {validURL} from "../../../helpers/LogicHelper";
import axios from "axios";

const AddNotification = ({setNotifications}) => {

    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            '& .heading': {
                fontWeight: 'bold',
                fontSize: is430pxBelow ? '16px' : '20px',
                color: Colors.dark3,
                margin: 0,
            },
            '& .card-con': {
                width: '100%',
                marginTop: '20px',
                borderRadius: '10px',
                background: Colors.light,
                '& .card': {
                    width: '85%',
                    margin: '0 auto',
                    padding: '25px 0',
                    '& div': {
                        width: '100%',
                        marginBottom: '25px',
                        '& h6': {
                            fontSize: '16px',
                            color: Colors.dark3,
                            margin: '0 0 10px',
                        },
                        '& input': {
                            width: '100%',
                            maxWidth: '100%',
                            boxSizing: 'border-box;',
                            borderRadius: '5px',
                            border: `1px solid ${Colors.light2}`,
                            padding: is770pxBelow ? '10px' : '15px 10px',
                            '&:focus': {
                                outline: 0
                            },
                            '&::placeholder': {
                                fontSize: '14px',
                            }
                        }
                    },
                    '& button': {
                        width: '100%',
                        color: Colors.light,
                        fontFamily: 'inherit',
                        borderRadius: '5px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        background: Colors.secondary,
                        padding: is770pxBelow ? (is430pxBelow ? '6px' : '10px') : '12px',
                        border: 0,
                        '&:focus': {
                            outline: 0
                        },
                    }
                }
            }
        }
    })

    const classes = useStyles()

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const defaultData = {
        title: '',
        description: '',
        link: ''
    }

    const [data, setData] = useState(defaultData);

    const onDataChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const onUploadClick = () => {
        const {title, description, link} = data;
        if (title !== '' && description !== '' && link !== ''){
            if (validURL(link)){
                setOpen(true)
                axios.post('/post/pushNotification', {data}).then(res => {
                    if (res.data.key === 'success'){
                        axios.get('/get/notifications').then(res => {
                            setNotifications(res.data)
                            setData(defaultData)
                            setOpen(false)
                        })
                    }else {
                        setOpen(false)
                        setErrorMessage(res.data.message)
                        setOpenError(true)
                    }
                })
            } else {
                setErrorMessage('Enter a valid Url')
                setOpenError(true)
            }
        } else {
            setErrorMessage('All Fields are required')
            setOpenError(true)
        }
    }


    return (
        <div className={classes.container}>
            <h6 className={'heading'}>Add New Notification</h6>
            <div className={'card-con'}>
                <div className={'card'}>
                    <div>
                        <h6>Title</h6>
                        <input type="text" value={data.title} name={'title'} onChange={onDataChange}
                               placeholder={'Enter Notification Title'}/>
                    </div>
                    <div>
                        <h6>Description</h6>
                        <input type="text" value={data.description} name={'description'} onChange={onDataChange}
                               placeholder={'Enter Notification Description'}/>
                    </div>
                    <div>
                        <h6>Link</h6>
                        <input type="text" value={data.link} name={'link'} onChange={onDataChange}
                               placeholder={'Enter Notification Link'}/>
                    </div>
                    <button onClick={onUploadClick}>Upload</button>
                </div>
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage}/>
            <FullScreenProgress open={open} setOpen={setOpen}/>
        </div>
    );
};

export default AddNotification;
