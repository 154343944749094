import React, { useState } from 'react';
import {
    Paper,
    Table, TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    useMediaQuery
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Colors } from "../../../helpers/Colors";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const BannerTable = ({ banners, setBanners, title, setReload, reload , slug , editSlug , deleteSlug}) => {

    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const columns = [
        {
            name: 'Phone Banner',
            align: 'left',
        },
        {
            name: 'Desktop Banner',
            align: 'center',
        },
        {
            name: 'Name',
            align: 'center',
        },
        {
            name: 'Action',
            align: 'right',
        },
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        topBar: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 0 20px',
        },
        title: {
            fontWeight: 'bold',
            color: Colors.dark3,
            margin: '0',
            fontSize: is430pxBelow ? '16px' : '20px'
        },
        addButton: {
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${Colors.secondary}`,
            background: Colors.secondary,
            padding: is430pxBelow ? '5px 10px' : '8px 18px',
            cursor: 'pointer',
            transition: 'all .4s',
            '&:hover': {
                transition: 'all .4s',
                background: Colors.light,
                '& .icon': {
                    color: Colors.secondary,
                },
                '& h6': {
                    color: Colors.secondary,
                }
            },
            borderRadius: '5px',
            '& .icon': {
                fontSize: is430pxBelow ? '12px' : '14px',
                marginRight: '6px',
                color: Colors.light,
            },
            '& h6': {
                fontWeight: 'bold',
                color: Colors.light,
                margin: '0',
                fontSize: is430pxBelow ? '13px' : '15px'
            }
        },
        container: {
            maxHeight: 650,
            borderRadius: '5px',
            zIndex: -1,
        },
        colTextStyle: {
            fontWeight: 'bold',
            color: Colors.light,
            margin: 0,
            fontSize: is430pxBelow ? '12px' : '16px'
        },
        tableRow: {
            '&:hover': {
                backgroundColor: Colors.primaryLight + ' !important',
            },
        },
        rowTextStyle: {
            color: Colors.dark3,
            fontSize: is430pxBelow ? '11px' : '14px',
            maxWidth: '200px',
            margin: 0,
            '& .checked': {
                fontSize: '22px',
                color: Colors.success,
            },
            '& .close': {
                fontSize: '20px',
                color: Colors.error,
            },
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 auto',
            justifyContent: 'center',
            background: Colors.secondary,
            padding: '5px 10px',
            border: `1px solid ${Colors.secondary}`,
            width: '40%',
            borderRadius: '5px',
            transition: 'all .4s',
            cursor: 'pointer',
            '&:hover': {
                transition: 'all .4s',
                border: `1px solid ${Colors.primary}`,
                background: Colors.primary,
                '& .icon': {
                    color: Colors.secondary,
                },
                '& h6': {
                    color: Colors.secondary,
                }
            },
            '& h6': {
                color: Colors.primary,
                fontSize: is430pxBelow ? '11px' : '14px',
                margin: 0
            }
        },
        deleteIcon: {
            fontSize: is430pxBelow ? '18px' : '22px',
            color: Colors.error,

        },
        imgContainer: {
            width: '90px',
            height: '70px',
            border: `1px solid ${Colors.light2}`,
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
                width: '90%',
                maxWidth: '90%',
                maxHeight: '90%'
            }
        }
    });

    const classes = useStyles();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: Colors.primary,
            color: Colors.light,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const navigate = useNavigate()

    const onDeleteProductClick = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: Colors.error,
            cancelButtonColor: Colors.success,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/delete/${deleteSlug}`, { data: { id } }).then(res => {
                    Swal.fire(
                        'Deleted!',
                        'Category has been deleted.',
                        'success'
                    ).then(() => {
                        setReload(!reload);
                        // axios.get('/get/banners').then(res => {
                        //     setBanners(res.data)
                        // })
                    })
                })
            }
        })
    }

    const onEditClick = (id) => {
        navigate(`/banners/${editSlug}/${id}`)
    }

    return (
        <>
            <div className={classes.topBar}>
                <h6 className={classes.title}>{title}</h6>
                <div className={classes.addButton} onClick={() => navigate(`/banners/${slug}`)}>
                    <BsPlusLg className={'icon'} />
                    <h6>Add Banner</h6>
                </div>
            </div>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map(col => (
                                        <StyledTableCell align={col.align}>
                                            <h6 className={classes.colTextStyle}>{col.name}</h6>
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {banners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" className={classes.tableRow} tabIndex={-1} key={row}>
                                        <TableCell align={'left'}>
                                            <div className={classes.imgContainer}>
                                                <img src={row.smallImageUrl} alt={'Banner'} />
                                            </div>
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            <div className={classes.imgContainer} style={{ margin: '0 auto' }}>
                                                <img src={row.largeImageUrl} alt={'Banner'} />
                                            </div>
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            <h6 className={classes.rowTextStyle} style={{ margin: '0 auto' }}>{row.name}</h6>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <div>
                                                <MdModeEdit style={{ color: Colors.primary, marginRight: '8px' }} className={classes.deleteIcon} onClick={() => onEditClick(row._id)} />
                                                <MdDelete className={classes.deleteIcon} onClick={() => onDeleteProductClick(row._id)} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    banners.length === 0 ? <h6 style={{ textAlign: 'center', fontSize: '14px', marginTop: '25px', color: Colors.dark3 }}>No Data Found</h6> : <></>
                }
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={banners.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default BannerTable;
